import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2a046eea&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoanDecision: require('/codebuild/output/src195787613/src/cc-portal/components/LoanDecision.vue').default,LoanSettlement: require('/codebuild/output/src195787613/src/cc-portal/components/LoanSettlement.vue').default,LoanSubmission: require('/codebuild/output/src195787613/src/cc-portal/components/LoanSubmission.vue').default})
